import React from 'react'
import './FooterStyles.css'
import { FiFacebook, FiInstagram } from 'react-icons/fi';
import { BsLinkedin } from 'react-icons/bs';
import { images } from '../../constants';

const Footer = () => {
    return (

        <div className="app__footer section__padding" id="login">
    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Me</h1>
        <p className="p__opensans">inkantocontact@gmail.com</p>
        <p className="p__opensans"></p>
        <p className="p__opensans"></p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.inkanto} alt="footer_logo" />
        <p className="p__opensans">&quot;The best way to predict the future is to create it.&quot;</p>
        <img src={images.pencil} className="pencil__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <a href="https://www.facebook.com/profile.php?id=100010539190507"><FiFacebook /></a>
          <a href="https://www.linkedin.com/in/ton-brus-b2281a299/"><BsLinkedin /></a>
          <a href="https://www.instagram.com/vihente_inkanto/"><FiInstagram /></a>
        </div>
      </div>
      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Active hours</h1>
        <p className="p__opensans"> Monday-Friday:</p>
        <p className="p__opensans"> 10:00  - 16:00 </p>
        <p className="p__opensans"> Saturday-Sunday:</p>
        <p className="p__opensans"> 09:00  - 11:00 </p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2023 Inkanto. All Rights reserved by Antonio V. Bruno.</p>
    </div>

  </div>
                    

                
    )
}

export default Footer