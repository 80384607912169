import React from 'react';
import { images } from '../../constants';
import './RecoveryStyles.css';

const Recovery = () => (
  <div className="app__bg app__wrapper section__padding" id="about">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.why} alt="Anto_image" />
    </div>
    <div className="app__wrapper_info">
      <br></br>
      <h1 className="headtext__cormorant">Why the name "Inkanto"?</h1>

      <div className="app__Anto-content">
        <div className="app__Anto-content_quote">
          <p className="p__opensans"><br></br>I began drawing with ink pens and crayons because it was all I could find at home, those memories called out the term "Ink" in my head, tied to it, I decided to give it something more, something very personal to me: My name; "Anto" in this case. <br></br><br/> 
                    As a result, the name of this website was born. The words "ink" and "Anto" are combined to form the word "Inkanto."
 </p>
        </div>
        <p className="p__opensans"> </p>
      </div>

      <div className="app__Anto-sign">
        <p>More Lore</p>
        <br></br><br></br>
        <p className="p__opensans">My dear friend if you are still reading I won't be exagerating saying that I'm a little moved... “Incanto” is an Italian word that translates to “enchantment” or “fascination” in English. It can also mean “charm”, or “spell”. You should have already grasped my love for the fantastical and imaginary world. For me, the act of creating involves breaking away from what already exists and producing something innovative, having an open minded mentality is a must for me. With this in mind, I believed that this name would be an excellent choice for my website!
         </p>
        {/*<img src={images.sign} alt="sign_image" />*/}
      </div>
      
     <br></br>
     <br></br>
     <br></br>
    </div>
  </div>
);

export default Recovery;