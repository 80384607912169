import React from 'react'
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Header from './components/Header/Header';
import './App.css';

function App() {
  return (
    <>
      <Navbar />
      <Header />
      <Footer />
    </>
  );
}

export default App;