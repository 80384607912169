import React from 'react'

import Navbar from '../components/navbar/Navbar'
import Recovery from '../components/recovery/Recovery'
import Footer from '../components/footer/Footer'
import Anto from '../components/Anto/Anto'
import Lore from '../components/Lore/Lore'
import SmoothScroll from '../components/SmoothScroll';

const RecoveryPage = () => {
    return (
        <>
        <SmoothScroll>
           <Navbar /> 
           <Anto />
           <Lore />
           <Recovery />
           <Footer />
           </SmoothScroll>
        </>
    )
}

export default RecoveryPage