import React from 'react';
import { images } from '../../constants';
import './Gallery.css';

 
const slider = () => {
    return (
<body>

  <div className="grid-wrapper" >

    <a href='#pop'><div className='Imago'><img src={images.Demo1}/></div></a>
    <a href='#pop2'><div className='Imago'><img src={images.Demo2}/></div></a>
    <a href='#pop3'><div className='Imago'><img src={images.Demo3}/></div></a>
    <a href='#pop4'><div className='Imago'><img src={images.Demo4}/></div></a>
    <a href='#pop5'><div className='Imago'><img src={images.Demo5}/></div></a>
    <a href='#pop6'><div className='Imago'><img src={images.Demo6}/></div></a>
    <a href='#pop7'><div className='Imago'><img src={images.Demo7}/></div></a>
    <a href='#pop8'><div className='Imago'><img src={images.Demo8}/></div></a>
    <a href='#pop9'><div className='Imago'><img src={images.Demo9}/></div></a>
    <a href='#pop10'><div className='Imago'><img src={images.Demo10}/></div></a>
    <a href='#pop11'><div className='Imago'><img src={images.Demo11}/></div></a>
    <a href='#pop12'><div className='Imago'><img src={images.Demo12}/></div></a>
    <a href='#pop13'><div className='Imago'><img src={images.Demo13}/></div></a>
    <a href='#pop14'><div className='Imago'><img src={images.Demo14}/></div></a>
    <a href='#pop15'><div className='Imago'><img src={images.Demo15}/></div></a>
    <a href='#pop16'><div className='Imago'><img src={images.Demo16}/></div></a>
    <a href='#pop17'><div className='Imago'><img src={images.Demo17}/></div></a>
    <a href='#pop18'><div className='Imago'><img src={images.Demo18}/></div></a>
    <a href='#pop19'><div className='Imago'><img src={images.Demo19}/></div></a>
    <a href='#pop20'><div className='Imago'><img src={images.Demo20}/></div></a>
    <a href='#pop21'><div className='Imago'><img src={images.Demo21}/></div></a>
    <a href='#pop22'><div className='Imago'><img src={images.Demo22}/></div></a>
    <a href='#pop23'><div className='Imago'><img src={images.Demo23}/></div></a>
    <a href='#pop24'><div className='Imago'><img src={images.Demo24}/></div></a>
    <a href='#pop25'><div className='Imago'><img src={images.Demo25}/></div></a>
    <a href='#pop26'><div className='Imago'><img src={images.Demo26}/></div></a>
    <a href='#pop27'><div className='Imago'><img src={images.Demo27}/></div></a>
    <a href='#pop28'><div className='Imago'><img src={images.Demo28}/></div></a>
    <a href='#pop29'><div className='Imago'><img src={images.Demo29}/></div></a>
    <a href='#pop30'><div className='Imago'><img src={images.Demo30}/></div></a>
    <a href='#pop31'><div className='Imago'><img src={images.Demo31}/></div></a>
    <a href='#pop32'><div className='Imago'><img src={images.Demo32}/></div></a>
    <a href='#pop33'><div className='Imago'><img src={images.Demo33}/></div></a>
    <a href='#pop34'><div className='Imago'><img src={images.Demo34}/></div></a>
    <a href='#pop35'><div className='Imago'><img src={images.Demo35}/></div></a>
    <a href='#pop36'><div className='Imago'><img src={images.Demo36}/></div></a>
    <a href='#pop37'><div className='Imago'><img src={images.Demo37}/></div></a>
    <a href='#pop38'><div className='Imago'><img src={images.Demo38}/></div></a>
    <a href='#pop39'><div className='Imago'><img src={images.Demo39}/></div></a>
    <a href='#pop40'><div className='Imago'><img src={images.Demo40}/></div></a>
    <a href='#pop41'><div className='Imago'><img src={images.Demo41}/></div></a>
    <a href='#pop42'><div className='Imago'><img src={images.Demo42}/></div></a>
    <a href='#pop43'><div className='Imago'><img src={images.Demo43}/></div></a>
    <a href='#pop44'><div className='Imago'><img src={images.Demo44}/></div></a>
  

    <div className='popuplightboxes'>
      <div className='popuplightbox' id='pop'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo1}/>

          <h3 className='popuptitle'>Distorsion</h3>
          <p className='popupbody'>Digital Art created with PS and Procreate</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop2'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo2}/>

          <h3 className='popuptitle'>Dalia's Gardens</h3>
          <p className='popupbody'>Digital Art created with Procreate, radical thoughts are hard to manage, stick to your senses.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop3'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo3}/>

          <h3 className='popuptitle'>Tipical floreal pattern</h3>
          <p className='popupbody'>Digital Art created with Procreate, anchored with Illustrator, made to be printed as a pattern.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop4'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo4}/>

          <h3 className='popuptitle'>Direct Hit</h3>
          <p className='popupbody'>Digital Logo, icon was made with procreate, edited in Ps.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop5'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo5}/>

          <h3 className='popuptitle'>SR Icon</h3>
          <p className='popupbody'>SOLD - Icon created to be printed on coasters.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop6'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo6}/>

          <h3 className='popuptitle'>Burning with passion</h3>
          <p className='popupbody'>SOLD -Flat digital Logo commission for a gardener, keywords given: passion, gardening, japanese.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop7'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo7}/>

          <h3 className='popuptitle'>Boom</h3>
          <p className='popupbody'>Digital Art Icon, experiment for firework icon.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop8'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo8}/>

          <h3 className='popuptitle'>I don't hear you</h3>
          <p className='popupbody'>Commission for Tremens Music musical video.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop9'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo9}/>

          <h3 className='popuptitle'>I'm sorry</h3>
          <p className='popupbody'>Digital Art created with procreate, I used to put letters under the doorstep to say sorry when I was a child.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop10'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo10}/>

          <h3 className='popuptitle'>Inclement Weather</h3>
          <p className='popupbody'>Digital Art created with Procreate, character design for "Weather avatar".</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop11'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo11}/>

          <h3 className='popuptitle'>Chester</h3>
          <p className='popupbody'>Digital Art created with Procreate, character design for a chest mascot.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop12'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo12}/>

          <h3 className='popuptitle'>Junglemania</h3>
          <p className='popupbody'>Digital Art created with Procreate, jungle sunset.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop13'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo13}/>

          <h3 className='popuptitle'>Golden Hour</h3>
          <p className='popupbody'>Digital Art created with Procreate, Milky.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop14'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo14}/>

          <h3 className='popuptitle'>Pirates</h3>
          <p className='popupbody'>Digital Art created with Procreate, pirates didn't have freedom at all, but they believed in it.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop15'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo15}/>

          <h3 className='popuptitle'>Holy</h3>
          <p className='popupbody'>Digital Art created with Procreate, his name is Holy.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop16'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo16}/>

          <h3 className='popuptitle'>Cringe Emoticon</h3>
          <p className='popupbody'>SOLD - Digital Art created with Procreate, commission for a twitch emote.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop17'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo17}/>

          <h3 className='popuptitle'>Lit Emote</h3>
          <p className='popupbody'>SOLD - Digital Art created with Procreate, commission for a twitch emote.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop18'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo18}/>

          <h3 className='popuptitle'>2 EZ emote</h3>
          <p className='popupbody'>SOLD - Digital Art created with Procreate, commission for a twitch emote.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop19'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo19}/>

          <h3 className='popuptitle'>Really? Emote</h3>
          <p className='popupbody'>SOLD - Digital Art created with Procreate, commission for a twitch emote.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop20'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo20}/>

          <h3 className='popuptitle'>Calculated Emote</h3>
          <p className='popupbody'>Digital Art created with Procreate, squared emote.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop21'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo21}/>

          <h3 className='popuptitle'>Want to be a star? V1</h3>
          <p className='popupbody'>Digital Art created with Procreate.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop22'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo22}/>

          <h3 className='popuptitle'>Want to be a star? V2</h3>
          <p className='popupbody'>Digital Art created with Procreate.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop23'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo23}/>

          <h3 className='popuptitle'>Copycat</h3>
          <p className='popupbody'>Digital Art created with PS and Procreate, logo design for a Cosplay maker.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop24'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo24}/>

          <h3 className='popuptitle'>Taco it away</h3>
          <p className='popupbody'>Digital Art created with PS and Procreate, logo design for a taco business.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop25'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo25}/>

          <h3 className='popuptitle'>Cheese it</h3>
          <p className='popupbody'>Digital Art created with PS and Procreate, logo design for an tech business.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop26'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo26}/>

          <h3 className='popuptitle'>Dritti al succo</h3>
          <p className='popupbody'>Digital Art created with PS and Procreate, logo design for a juice business.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop27'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo27}/>

          <h3 className='popuptitle'>Herda</h3>
          <p className='popupbody'>SOLD - Digital Art created with PS and Procreate for a podcast.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop28'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo28}/>

          <h3 className='popuptitle'>Herda Logo</h3>
          <p className='popupbody'>SOLD - Digital Art logo created with PS and Procreate for a podcast.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop29'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo29}/>

          <h3 className='popuptitle'>Boo</h3>
          <p className='popupbody'>SOLD - Pixel art commission created for a 2D game project.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop30'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo30}/>

          <h3 className='popuptitle'>Far Away</h3>
          <p className='popupbody'>Digital Art created with Procreate for an animation.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop31'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo31}/>

          <h3 className='popuptitle'>Zombie Bear</h3>
          <p className='popupbody'>SOLD - Pixel art commission created for a 2D game project.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop32'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo32}/>

          <h3 className='popuptitle'>Cloudy</h3>
          <p className='popupbody'>SOLD - Digital Art commission created with and Procreate and flash for an iconic character.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop33'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo33}/>

          <h3 className='popuptitle'>Melt</h3>
          <p className='popupbody'>SOLD - Digital Art portrait commission created with Procreate and flash.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop34'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo34}/>

          <h3 className='popuptitle'>ZUZU</h3>
          <p className='popupbody'>SOLD - Digital Art commission created with Procreate for an online avatar.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop35'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo35}/>

          <h3 className='popuptitle'>Red Square</h3>
          <p className='popupbody'>Digital Art wallpaper commission created with Procreate.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop36'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo36}/>

          <h3 className='popuptitle'>Night Shade</h3>
          <p className='popupbody'>SOLD - Customized Digital FanArt commission created with PS and Procreate.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop37'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo37}/>

          <h3 className='popuptitle'>Dark Night</h3>
          <p className='popupbody'>SOLD - Digital Art created with PS and Procreate for a black metal album cover</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop38'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo38}/>

          <h3 className='popuptitle'>F L O W E R S</h3>
          <p className='popupbody'>Digital Art created with Procreate</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop39'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo39}/>

          <h3 className='popuptitle'>Continent</h3>
          <p className='popupbody'>Digital Art created with Procreate, manga cover style</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop40'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo40}/>

          <h3 className='popuptitle'>Pot of greed</h3>
          <p className='popupbody'>Digital Art depicting "greed"created with Procreate.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop41'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo41}/>

          <h3 className='popuptitle'>I can see you</h3>
          <p className='popupbody'>Digital Art created with PS and Procreate to be stamped on shirts and apparel.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop42'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo42}/>

          <h3 className='popuptitle'>King of the swamp</h3>
          <p className='popupbody'>Digital Art created with PS and Procreate to be stamped on shirts and apparel.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop43'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo43}/>

          <h3 className='popuptitle'>Heroes never die</h3>
          <p className='popupbody'>Digital Art created with PS and Procreate to be stamped on shirts and apparel.</p>

        </div>
      </div>

      <div className='popuplightbox' id='pop44'>
        <div className='popupcontent'>
          <a href="#!" className='close'></a>
          <img src={images.Demo44}/>

          <h3 className='popuptitle'>Corvette</h3>
          <p className='popupbody'>Digital Art created with Procreate, character design for videogames.</p>

        </div>
      </div>

    </div>
    
  </div>
</body>
)
}

export default slider;