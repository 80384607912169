import React from 'react';
import { images } from '../../constants';
import './Lore.css';

const Lore = () => (
  <div className="app__bg app__wrapper1 section__padding" id="about">
     <div className="app__wrapper_imgo app__wrapper_img-reverseo">
      <img src={images.Prismo} alt="Anto_image" />
    </div>
    <div className="app__wrapper_info">
      <br></br>
      <h1 className="headtext__cormorant">My Goals and Dreams</h1>

      <div className="app__Anto-content">
        <div className="app__Anto-content_quote">
          <p className="p__opensans">As previously stated, I am an independent artist and my ambition is to reach as many people as possible with my creations. I believe in art and its ability to communicate what cannot be said.<br></br>
                      I want to assist not just my customers, but also the people around me to share their inner core.
                      <br></br><br></br>
                      I have directed my studies towards enhancing my ability to connect with people in the most flexible way possible. By developing a deeper understanding of different perspectives, communication styles, and cultural backgrounds, I aim to establish effective and meaningful relationships with individuals from diverse backgrounds; Everyone deserves to be listened.
                      And talking about listening, strong teams rely on clear communication, being part of a team is really something, feel free to ask me collaborations or just ask me if you want me to be part of your team! Alone we can achieve many things but together we can do much more!
 </p>
        </div>
        <p className="p__opensans"> </p>
      </div>

     <br></br>
     <br></br>
     <br></br>
    </div>
   
  </div>
  
);

export default Lore;