import React from 'react'
import Contact from '../components/contact/Contact'
import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'
import SmoothScroll from '../components/SmoothScroll';

const ContactPage = () => {
    return (
        <>
        <SmoothScroll>
            <Navbar/>
            <Contact />
            <Footer />
             </SmoothScroll>  
        </>
    )
}

export default ContactPage