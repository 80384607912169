import React from 'react';
import { images } from '../../constants';
import './Anto.css';

const Anto = () => (
  <div className="app__bg app__wrapper section__padding" id="about">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.Io} alt="Anto_image" />
    </div>
    <div className="app__wrapper_info">
      <br></br>
      <h1 className="headtext__cormorant">About Me</h1>

      <div className="app__Anto-content">
        <div className="app__Anto-content_quote">
          <p className="p__opensans">Hello, my name is Antonio, and I work as an independent artist. I began drawing as a child and have never stopped. I'm originally from Venezuela but now live in Italy. I enjoy learning languages (either human or machine ones) and traveling; I studied languages and modern cultures at the University of Trieste and have worked with a wide variety of artists.
 
          <br></br>Download my CV if you want to learn more about my career! In the meantime, please visit my website; I hope you enjoy it!
            
 </p>
        </div>
        <p className="p__opensans"> </p>
      </div>

      <div className="app__Anto-sign">
        <p>Antonio V. Bruno</p>
        <p className="p__opensans">Author</p>
        {/*<img src={images.sign} alt="sign_image" />*/}
      </div>
      <button type="button" className="custom__button" style={{ top: '45px', position:'relative'}} ><a href="https://drive.google.com/file/d/1hsKrdKm4UsCb-55VSRJy2nmsR6OKYTl5/view?usp=drive_link" download>Download CV</a></button>
     <br></br>
     <br></br>
     <br></br>
    </div>
  </div>
);

export default Anto;