import React from 'react';


import { images } from '../../constants';
import './Header.css';

/*import { SubHeading } from '../../components';*/

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info" style={{ bottom: '40px', position: 'relative' }}>
      {/*<SubHeading title="Chase your dreams" style={{ color: 'black' }} />*/}
      <h2 className="app__header-h2">Chase your dreams</h2>
      <h1 className="app__header-h1">WELCOME TO INKANTO</h1>
      {/*<p className="p__opensans" style={{ margin: '2rem 0', color: 'black' }}>Feel free to check every corner, relax and take it easy.</p>
      <button type="button" className="custom__button" style={{ top: '45px', position:'relative'}} >Explore the catalogue</button>*/}
    </div>

    <div className="app__wrapper_img">
      <div className="app__wrapper-head_img">
      <img src={images.welcome} alt="header_imag" />
      </div>
    </div>
  </div>
);


export default Header;