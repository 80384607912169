import React from 'react';
import './ServicesStyle.css';

import { BsGlobe2 } from "react-icons/bs";
import { BsFillBox2HeartFill } from "react-icons/bs";
import { BsImageFill } from "react-icons/bs";
import { BsPlayBtnFill } from "react-icons/bs";
import { BsEyeFill } from "react-icons/bs";
import { BsHeartHalf } from "react-icons/bs";


const Service = () => (
    <div className="app__bg app__wrapper section__padding" id="about">
<section>
      <div class="row">
        <h2 class="section-heading"></h2>
      </div>
      <div class="row">
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
            <i><BsImageFill  /></i>
            </div>
            <h3>Digital Art</h3>
            <p>
              I will create digital art in any format if possible in my style, check out my works to have an idea of what I can do.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
            <i><BsGlobe2 /></i>
            </div>
            <h3>Web Design</h3>
            <p>
              I will create from zero your Website Build, I can provide you with separate HTML-CSS-JS files or create an app with React or a CMS (Wordpress is prefered)
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
              <i><BsPlayBtnFill /></i>
            </div>
            <h3>Handmade Animation</h3>
            <p>
              I will create hand made animation in my style for any legal purpose, check out my works to have an idea of what I can do.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
              <i><BsEyeFill /></i>
            </div>
            <h3>Customization</h3>
            <p>
              I will make you website, account content or channel content more linear with a style or create them myself.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
              <i><BsFillBox2HeartFill /></i>
            </div>
            <h3>Mockups and product care</h3>
            <p>
              I will create for you mockups and advertising for your products, you can furnish me your logo and brand icons or make me do them.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
              <i><BsHeartHalf /></i>
            </div>
            <h3>Project collaboration</h3>
            <p>
              Me and a team of selected personel will provide you with content related to your project. 
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>

);

export default Service;