import React from 'react';
import { images } from '../../constants';
import './HeroStyles.css';

const Hero = () => (
  <div className="app__bg app__wrapper section__padding" id="about">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.Insta} alt="Anto_image"/>
    </div>
    <div className="app__wrapper_info">
      <br></br>
      <h1 className="headtext__cormorant">Visit my Instagram Portfolio!</h1>

      <div className="app__Anto-content">
        <div className="app__Anto-content_quote">
          <p className="p__opensans">
 Drop a like if you want or follow me, DM me anything if you like what I do!
          <br></br>
          Here you can check some of my best works to have an idea of my style or what I have already done for my clients!
            
 </p>
        </div>
        <p className="p__opensans"> </p>
      </div>

      <div className="app__Anto-sign">
        <p></p>
        <p className="p__opensans"></p>
        {/*<img src={images.sign} alt="sign_image" />*/}
      </div>
      <button type="button" className="custom__button" style={{ top: '45px', position:'relative'}} ><a href="https://www.instagram.com/vihente_inkanto/" download>Take a look!</a></button>
     <br></br>
     <br></br>
     <br></br>
    </div>
  </div>
);

export default Hero;
