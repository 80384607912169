import React from 'react'

import Navbar from '../components/navbar/Navbar'
import Service from '../components/Services/Services'
import Footer from '../components/footer/Footer'
import Hero from '../components/hero/Hero'
import SmoothScroll from '../components/SmoothScroll';

const CloudPage = () => {
    return (
        <>
        <SmoothScroll>
           <Navbar /> 
           <Service />
           <Hero />
           <Footer />
           </SmoothScroll>
        </>
    )
}

export default CloudPage