import bg from '../assets2/bg.png';
import Io from '../assets2/Io.png';
import overlaybg from '../assets2/overlaybg.png';
import pencil from '../assets2/pencil.png';
import welcome from '../assets2/welcome.png';
import why from '../assets2/why.png';
import inkanto from '../assets2/inkanto.png';
import inkantosmall from '../assets2/inkantosmall.png';
import AVB from '../assets2/Curriculum_Antonio_Vicente_Bruno.pdf';
import Servizi from '../assets2/Servizi.png';
import Prismo from '../assets2/Prismo.png';
import Insta from '../assets2/Insta.png';
import Demo1 from '../assets3/Demo1.PNG';
import Demo2 from '../assets3/Demo2.PNG';
import Demo3 from '../assets3/Demo3.PNG';
import Demo4 from '../assets3/Demo4.PNG';
import Demo5 from '../assets3/Demo5.PNG';
import Demo6 from '../assets3/Demo6.PNG';
import Demo7 from '../assets3/Demo7.PNG';
import Demo8 from '../assets3/Demo8.PNG';
import Demo9 from '../assets3/Demo9.PNG';
import Demo10 from '../assets3/Demo10.PNG';
import Demo11 from '../assets3/Demo11.PNG';
import Demo12 from '../assets3/Demo12.PNG';
import Demo13 from '../assets3/Demo13.PNG';
import Demo14 from '../assets3/Demo14.PNG';
import Demo15 from '../assets3/Demo15.PNG';
import Demo16 from '../assets3/Demo16.PNG';
import Demo17 from '../assets3/Demo17.PNG';
import Demo18 from '../assets3/Demo18.PNG';
import Demo19 from '../assets3/Demo19.PNG';
import Demo20 from '../assets3/Demo20.JPG';
import Demo21 from '../assets3/Demo21.PNG';
import Demo22 from '../assets3/Demo22.PNG';
import Demo23 from '../assets3/Demo23.PNG';
import Demo24 from '../assets3/Demo24.PNG';
import Demo25 from '../assets3/Demo25.PNG';
import Demo26 from '../assets3/Demo26.PNG';
import Demo27 from '../assets3/Demo27.PNG';
import Demo28 from '../assets3/Demo28.PNG';
import Demo29 from '../assets3/Demo29.PNG';
import Demo30 from '../assets3/Demo30.PNG';
import Demo31 from '../assets3/Demo31.PNG';
import Demo32 from '../assets3/Demo32.PNG';
import Demo33 from '../assets3/Demo33.JPG';
import Demo34 from '../assets3/Demo34.JPG';
import Demo35 from '../assets3/Demo35.PNG';
import Demo36 from '../assets3/Demo36.PNG';
import Demo37 from '../assets3/Demo37.PNG';
import Demo38 from '../assets3/Demo38.PNG';
import Demo39 from '../assets3/Demo39.PNG';
import Demo40 from '../assets3/Demo40.PNG';
import Demo41 from '../assets3/Demo41.PNG';
import Demo42 from '../assets3/Demo42.PNG';
import Demo43 from '../assets3/Demo43.PNG';
import Demo44 from '../assets3/Demo44.PNG';
import Demo45 from '../assets3/Demo45.PNG';

export default {
  bg,
  Io,
  overlaybg,
  pencil,
  inkanto,
  welcome,
  inkantosmall,
  AVB,
  why,
  Servizi,
  Insta,
  Prismo,
  Demo1, 
  Demo2, 
  Demo3, 
  Demo4, 
  Demo5, 
  Demo6, 
  Demo7, 
  Demo8,
  Demo9, 
  Demo10,
  Demo11, 
  Demo12, 
  Demo13, 
  Demo14, 
  Demo15, 
  Demo16, 
  Demo17, 
  Demo18, 
  Demo19, 
  Demo20, 
  Demo21, 
  Demo22, 
  Demo23, 
  Demo24, 
  Demo25, 
  Demo26, 
  Demo27, 
  Demo28, 
  Demo29, 
  Demo30, 
  Demo31, 
  Demo32, 
  Demo33,
  Demo34, 
  Demo35, 
  Demo36, 
  Demo37, 
  Demo38, 
  Demo39,
  Demo40,
  Demo41,
  Demo42,
  Demo43,
  Demo44,
  Demo45,  
};