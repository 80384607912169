import React from 'react'
import Gallery from '../components/Gallery/Gallery'
import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'
import SmoothScroll from '../components/SmoothScroll';

const GalleryPage = () => {
    return (
        <>
        <SmoothScroll>
            <Navbar />
            <Gallery />
            <Footer />
            </SmoothScroll> 
        </>
    )
}

export default GalleryPage